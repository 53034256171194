<template>
  <a-card>
    <a-spin tip="加载中" :spinning="spinning_status">
      <h3>
        新建规则<span class="header_title"
          >最多可导出近90天数据，如需要导出90天以前的数据请联系数据分析处理</span
        >
      </h3>
      <div class="form_box">
        <a-form-model
          :model="form"
          v-bind="layout"
          ref="ruleForm"
          :rules="rules"
        >
          <a-form-model-item label="规则名称" prop="rule_name">
            <a-input
              v-model="form.rule_name"
              placeholder="请输入"
              style="width: 300px"
            />
          </a-form-model-item>
          <a-form-model-item label="规则描述">
            <a-textarea
              v-model="form.rule_desc"
              placeholder="请输入"
              :auto-size="{ minRows: 3, maxRows: 5 }"
              style="width: 450px"
            />
          </a-form-model-item>
          <a-form-model-item label="模型" prop="model_code">
            <a-radio-group v-model="form.model_code" @change="selectModel">
              <a-radio-button
                :value="item.code"
                v-for="item in info_data"
                :key="item.code"
              >
                {{ item.name }}
              </a-radio-button>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item
            label="维度"
            v-show="form.model_code"
            prop="dimensionality"
          >
            <a-radio-group
              v-model="form.dimensionality"
              @change="selectDimensionality"
            >
              <a-radio-button
                :value="item.code"
                v-for="(item, index) in dimensionality_list"
                :key="index"
              >
                {{ item.name }}
              </a-radio-button>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="平台" v-show="form.dimensionality">
            <a-checkbox-group v-model="form.platform">
              <a-checkbox
                :value="item.code"
                v-for="(item, index) in platform_list"
                :key="index"
              >
                {{ item.name }}
              </a-checkbox>
            </a-checkbox-group>
          </a-form-model-item>
          <a-form-model-item
            label="媒体帐号范围"
            prop="media_id"
            v-show="param_list.length"
          >
            <a-select
              v-model="form.media_id"
              show-search
              option-filter-prop="children"
              allow-clear
              style="width: 300px"
              placeholder="请选择"
              :not-found-content="fetching ? undefined : '暂无数据'"
              @search="searchMidia"
              label-in-value
            >
              <a-spin
                v-if="fetching"
                slot="notFoundContent"
                size="small"
                tip="加载中..."
              ></a-spin>
              <a-select-option
                :value="item.id"
                v-for="item in brand_list"
                :key="item.id"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item
            label="筛选方式"
            prop="media_type"
            v-if="other_conditions.length > 1 && param_list.length"
          >
            <a-select
              v-model="form.media_type"
              show-search
              option-filter-prop="children"
              allow-clear
              style="width: 300px"
              placeholder="请选择"
            >
              <a-select-option
                :value="item.condition_type"
                v-for="(item, index) in other_conditions"
                :key="index"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="筛选条件" v-show="param_list.length">
            <a-row>
              <a-col :span="8" class="col_border border_r"> 表头 </a-col>
              <a-col :span="8" class="col_border border_r"> 筛选方式 </a-col>
              <a-col :span="8" class="col_border"> 筛选值 </a-col>
            </a-row>
          </a-form-model-item>
          <!-- 筛选条件添加 -->
          <a-form-model-item
            :wrapper-col="btn_layout"
            v-for="domain in filtrate"
            :key="domain.key"
            v-show="param_list.length"
          >
            <a-row :gutter="16">
              <a-col :span="7">
                <a-select
                  show-search
                  option-filter-prop="children"
                  allow-clear
                  placeholder="请选择表头"
                  v-model="domain.code"
                  @change="selectTable(domain)"
                >
                  <a-select-option
                    :value="k.code"
                    v-for="k in param_list"
                    :key="k.code"
                  >
                    {{ k.name }}
                  </a-select-option>
                </a-select>
              </a-col>
              <a-col :span="7">
                <a-select
                  v-model="domain.condition_type"
                  show-search
                  option-filter-prop="children"
                  allow-clear
                  placeholder="请选择筛选方式"
                  @change="(value) => selectFilter(value, domain)"
                >
                  <a-select-option
                    :value="item.condition_type"
                    v-for="item in domain.filter_type"
                    :key="item.condition_type"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-col>
              <a-col :span="7">
                <a-input-number
                  v-if="domain.type == 'int'"
                  v-model="domain.condition_value"
                  :precision="0"
                  :max="100000"
                  style="width: 250px"
                />
                <a-input
                  v-if="domain.type == 'string'"
                  v-model="domain.condition_value"
                  :maxLength="50"
                  style="width: 250px"
                />
                <a-date-picker
                  v-if="domain.type == 'date'"
                  style="width: 250px"
                  :disabled-date="disabledTime"
                  v-model="domain.condition_value"
                  format="YYYY-MM-DD"
                />
                <a-range-picker
                  v-if="domain.type == 'between'"
                  :placeholder="['开始时间', '结束时间']"
                  show-time
                  format="YYYY-MM-DD"
                  inputReadOnly
                  style="width: 250px"
                  v-model="domain.condition_value"
                  :disabled-date="disabledDate"
                  :ranges="{
                    本周: [$moment().startOf('week'), $moment().endOf('day')],
                    本月: [$moment().startOf('month'), $moment().endOf('day')],
                  }"
                />
              </a-col>
              <a-col :span="1">
                <a-icon
                  class="dynamic-delete-button"
                  type="minus-circle-o"
                  @click="removeDomain(domain)"
                />
              </a-col>
            </a-row>
          </a-form-model-item>
          <a-form-model-item
            :wrapper-col="btn_layout"
            v-show="param_list.length"
          >
            <a-button type="dashed" style="width: 60%" @click="addFiltrate">
              <a-icon type="plus" /> 添加
            </a-button>
          </a-form-model-item>
          <a-form-model-item label="表头(指标)" v-show="field_all_list.length">
            <a-radio-group @change="selectTableRadio">
              <a-radio-button
                :value="item.code"
                v-for="item in field_select"
                :key="item.code"
                style="margin-bottom: 12px"
              >
                <a-badge :offset="[8, -8]">
                  <a-icon
                    slot="count"
                    type="close-circle"
                    style="color: #f5222d"
                    v-if="item.code == table_active"
                    @click="deleteTable(item.code)"
                  />
                  {{ item.name }}
                </a-badge>
              </a-radio-button>
              <a-button type="link" @click="showTableSelect">编辑</a-button>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item label="排序条件" v-show="field_select.length">
            <a-row>
              <a-col :span="8" class="col_border border_r"> 表头 </a-col>
              <a-col :span="8" class="col_border"> 排序 </a-col>
            </a-row>
          </a-form-model-item>
          <a-form-model-item
            :wrapper-col="btn_layout"
            v-for="(sort, index) in sort_filter"
            :key="index"
            v-show="field_select.length"
          >
            <a-row :gutter="16">
              <a-col :span="7">
                <a-select
                  show-search
                  option-filter-prop="children"
                  allow-clear
                  v-model="sort.code"
                >
                  <a-select-option
                    :value="item.code"
                    v-for="item in field_select"
                    :key="item.code"
                  >
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-col>
              <a-col :span="7">
                <a-select
                  show-search
                  option-filter-prop="children"
                  allow-clear
                  v-model="sort.sort_type"
                >
                  <a-select-option value="asc"> 升序 </a-select-option>
                  <a-select-option value="desc"> 倒序 </a-select-option>
                </a-select>
              </a-col>
              <a-col :span="1">
                <a-icon
                  class="dynamic-delete-button"
                  type="minus-circle-o"
                  @click="removeSort(sort)"
                />
              </a-col>
            </a-row>
          </a-form-model-item>
          <a-form-model-item
            :wrapper-col="btn_layout"
            v-show="field_select.length"
          >
            <a-button type="dashed" style="width: 60%" @click="addSortField">
              <a-icon type="plus" /> 添加
            </a-button>
          </a-form-model-item>
          <a-form-model-item :wrapper-col="btn_layout">
            <a-button
              type="primary"
              size="large"
              @click="exportSave"
              :loading="btn_loading"
              :disabled="detail_edit"
            >
              提交并导出
            </a-button>
            <a-button
              style="margin-left: 16px"
              size="large"
              v-show="detail_id==0"
              @click="cancelReturn"
              >取消
            </a-button>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-spin>
    <table-select-modal
      :visible="show_modal"
      @colseModal="colseModal"
      :field_list="field_all_list"
      @selectFiled="selectFiled"
      :select_grounp="field_select"
      ref="tableSelect"
    />
  </a-card>
</template>
<script>
import _http from "@/api/core/Analyse.js";
import TableSelectModal from "./TableSelectModal.vue";
import debounce from "lodash/debounce";

export default {
  components: {
    TableSelectModal,
  },
  data() {
    this.searchMidia = debounce(this.searchMidia, 300);
    return {
      layout: {
        labelCol: { span: 4 },
        wrapperCol: { span: 16 },
      },
      btn_layout: {
        span: 18,
        offset: 4,
      },
      show_modal: false,
      form: {
        rule_name: "",
        rule_desc: "",
        model_code: "",
        dimensionality: "",
        platform: [],
        media_id: [],
        media_type: [],
      },
      rules: {
        rule_name: {
          required: true,
          message: "请填写规则名称",
          trigger: "blur",
        },
        media_id: {
          required: true,
          message: "请选择媒体帐号范围",
          trigger: "change",
        },
        media_type: {
          required: true,
          message: "请选择筛选方式",
          trigger: "change",
        },
        dimensionality: {
          required: true,
          message: "请选择维度",
          trigger: "change",
        },
        model_code: {
          required: true,
          message: "请选择模型",
          trigger: "change",
        },
      },
      brand_list: [],
      info_data: [],
      spinning_status: false,
      dimensionality_list: [],
      platform_list: [],
      filtrate: [],
      param_list: [],
      field_list: [],
      field_all_list: [],
      field_select: [],
      sort_filter: [],
      detail_id: this.$route.query.id,
      btn_loading: false,
      table_active: null,
      detail_data: {},
      other_conditions: [],
      fetching: false,
      detail_edit: this.$route.query.detail,
    };
  },
  mounted() {
    this.getDelaerAccount();
    if (this.detail_id != 0) {
      this.exportRuleDetail();
    } else {
      this.modelInit();
    }
  },
  methods: {
    // 编辑详情数据
    async exportRuleDetail() {
      await this.modelInit();
      _http.exportRuleDetail(this.detail_id).then((res) => {
        // console.log("res", res);
        if (res.code == 200) {
          this.detail_data = res.data;
          this.editDataDispose();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 模型数据初始化
    async modelInit() {
      this.spinning_status = true;
      await _http
        .getModelList()
        .then((res) => {
          // console.log("res", res);
          if (res.code == 200) {
            this.info_data = res.data;
          } else {
            this.$message.error(res.messgae);
          }
        })
        .finally(() => {
          this.spinning_status = false;
        });
    },

    // 编辑页数据初始化
    editDataDispose() {
      this.form.rule_name = this.detail_data.rule_name;
      this.form.rule_desc = this.detail_data.rule_desc;
      let model_info = JSON.parse(JSON.stringify(this.detail_data.model_info));
      this.form.model_code = model_info.model_code;
      this.selectModel(this.form.model_code);
      this.form.dimensionality = model_info.dimensionality;
      this.selectDimensionality(this.form.dimensionality);
      this.form.platform = model_info.platform;
      // 表头指标
      let field_list_arr = model_info.field_list.map(({ code }) => code);
      this.selectFiled(field_list_arr);
      // 排序条件
      this.sort_filter = model_info.sort_list;
      // 媒体帐号范围查询
      let media_list = model_info.param_list.filter(
        (item) => item.code == "EXTERNAL_DATA.account_group"
      );
      this.form.media_id = {
        key: media_list[0].condition_value,
        label: media_list[0].condition_display,
      };
      // 筛选条件
      this.filtrate = model_info.param_list.filter(
        (item) => item.code !== "EXTERNAL_DATA.account_group"
      );
      this.filtrate.map((item) => {
        this.selectTable(item, true);
        if (item.condition_type == "between") {
          item.condition_value = item.condition_value.split("~");
        }
        this.selectFilter(item.condition_type, item, true);
      });
    },
    // 媒体帐号范围
    getDelaerAccount(value) {
      let payload = {
        page_num: 1,
        page_size: 50,
        name: value || "",
      };
      this.fetching = true;
      _http
        .dealerTemplete(payload)
        .then((res) => {
          // console.log("resresresres", res);
          if (res.code == 200) {
            this.brand_list = res.data.list;
          } else {
            this.$message.error(res.message);
          }
        })
        .finally(() => {
          this.fetching = false;
        });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 媒体模糊搜索
    searchMidia(value) {
      // console.log("value", value);
      this.getDelaerAccount(value);
    },
    // handleChange(value){
    //   console.log(value)
    //   this.form.media_display = value.label.trim()
    //   this.form.media_value = value.key
    //   console.log('this.form.media_id',this.form.media_id,this.form.media_display)
    // },
    // 添加筛选条件
    addFiltrate() {
      this.filtrate.push({
        code: undefined,
        filter_type: [],
        condition_type: undefined,
        type: "",
        filter_value: [],
        condition_value: undefined,
        key: Date.now(),
      });
    },
    // 删除筛选条件
    removeDomain(item) {
      let index = this.filtrate.indexOf(item);
      if (index !== -1) {
        this.filtrate.splice(index, 1);
      }
    },
    /**
     * 筛选条件取最外层 param_list
     * 表头（指标）与排序条件取最外层 field_list 与 dimensionality_list中最内层field_list之和
     */
    // 选择模型
    selectModel(code) {
      this.form.dimensionality = "";
      this.form.platform = [];
      this.form.media_id = [];
      this.form.media_type = [];
      this.field_select = [];
      this.filtrate = [];
      this.sort_filter = [];
      this.field_all_list = [];
      let value = "";
      if (typeof code === "string") {
        value = code;
      } else {
        value = code.target.value;
      }
      let obj = this.info_data.filter((item) => item.code === value);
      this.dimensionality_list = obj[0].dimensionality_list;
      this.field_list = obj[0].field_list;
      this.field_all_list = this.field_list;
      this.param_list = obj[0].param_list;
      this.otherFilter();
    },
    /**
     * 根据模板查看是否有外部筛选条件
     * 匹配code 为 EXTERNAL_DATA.account_group 为外部方式（目前固定）
     * not_null:是否为必填项
     * conditions：长度大于1时显示筛选方式选择框
     * 过滤code 为 EXTERNAL_DATA.account_group 的筛选方式
     */
    otherFilter() {
      // console.log("this.param_list", this.param_list);
      let arr = this.param_list.filter((item) => {
        return item.code === "EXTERNAL_DATA.account_group";
      });
      this.param_list = this.param_list.filter((item) => {
        return item.code !== "EXTERNAL_DATA.account_group";
      });
      this.other_conditions = arr[0].conditions;
      if (arr[0].conditions.length == 1) {
        this.form.media_type = arr[0].conditions[0].condition_type;
      }
    },
    // 选择维度
    selectDimensionality(code) {
      this.form.platform = [];
      let value = "";
      if (typeof code === "string") {
        value = code;
      } else {
        value = code.target.value;
      }
      let obj = this.dimensionality_list.filter((item) => item.code === value);
      this.platform_list = obj[0].platform;
      this.field_all_list = this.field_list.concat(obj[0].field_list);
    },
    // 选择筛选表头
    selectTable(value, edit) {
      const { code, key } = value;
      let filter_arr = this.param_list.filter((item) => item.code == code);
      // console.log("filter_arr", filter_arr);
      this.filtrate.map((item) => {
        if (item.key === key) {
          if (!edit) {
            item.condition_type = [];
            item.condition_value = null;
          }
          item.filter_type = filter_arr[0].conditions;
          item.type = filter_arr[0].type;
        }
      });
    },
    // 筛选方式
    selectFilter(value, sel, edit) {
      const { code, key } = sel;
      let old_arr = this.param_list.filter((item) => item.code == code);
      let old_type = old_arr[0].type;
      let filter_arr = this.filtrate.filter((item) => item.key == key);
      this.filtrate.map((item) => {
        if (item.key == key) {
          if (!edit) {
            item.condition_value = null;
          }
          item.type = old_type;
          item.condition_type = value;
          filter_arr[0].filter_type.forEach((k) => {
            if (k.condition_type == value) {
              switch (k.condition_type) {
                case "between":
                  item.type = k.condition_type;
                  break;
                case "function_last_week":
                  item.type = "function_last_week";
                  break;
                case "function_last_month":
                  item.type = "function_last_month";
                  break;
                case "function_last_n_day":
                  item.type = k.type;
                  break;
                default:
                  break;
              }
            }
          });
        }
      });
    },

    // 保存选择
    selectFiled(value) {
      // console.log('value',value)
      let arr = [];
      this.field_all_list.map((item) => {
        // console.log('item.field_list',item)
        if(item.field_list){
          arr = arr.concat(item.field_list);
        }else{
          arr = arr.concat(item);
        }
      });

      let arr2 = [];
      arr.forEach((item) => {
        value.map((key) => {
          if (item.code == key) {
            arr2.push(item);
          }
        });
      });
      this.field_select = arr2;
    },
    // 删除某个表头指标
    deleteTable(code) {
      this.field_select = this.field_select.filter((item) => item.code != code);
      this.sort_filter = [];
    },
    //日期选择框
    onChangeDate(dates) {
      let ctime_from = "";
      let ctime_to = "";
      if (dates.length) {
        ctime_from = this.$moment(dates[0]).format("YYYY-MM-DD");
        ctime_to = this.$moment(dates[1]).format("YYYY-MM-DD");
        // this.search_form.time[0] = ctime_from;
        // this.search_form.time[1] = ctime_to;
      }
      return `${ctime_from}~${ctime_to}`;
    },
    // 单个日期
    onChangeOneDate(data) {
      let ctime_from = "";
      ctime_from = this.$moment(data).format("YYYY-MM-DD");
      return `${ctime_from}`;
    },
    disabledDate(current) {
      // Can not select days before today and today
      return current && current > this.$moment().endOf("day");
    },
    disabledTime(current) {
      return current > this.$moment().endOf("day");
    },
    // 选择表头
    selectTableRadio(e) {
      // console.log("valuevaluevalue", value);
      this.table_active = e.target.value;
    },
    // 添加排序条件
    addSortField() {
      this.sort_filter.push({
        code: undefined,
        sort_type: undefined,
        key: Date.now(),
      });
    },
    // 删除排序条件
    removeSort(item) {
      let index = this.sort_filter.indexOf(item);
      if (index !== -1) {
        this.sort_filter.splice(index, 1);
      }
    },
    showTableSelect() {
      this.show_modal = true;
      this.$refs.tableSelect.defaultValue();
    },

    // 关闭弹窗
    colseModal() {
      this.show_modal = false;
    },
    // 提交并导出
    exportSave() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (!this.field_select.length) {
            this.$message.error("请选择表头！");
            return;
          }
          let checkout = false;

          this.filtrate.forEach((item) => {
            if (!item.code || !item.condition_type) {
              checkout = true;
              return;
            }

            if (
              !item.condition_value &&
              item.type != "function_last_month" &&
              item.type != "function_last_week"
            ) {
              checkout = true;
              return;
            }
            checkout = false;
          });
          let checkout_sort = false;
          this.sort_filter.forEach((item) => {
            if (!item.code || !item.sort_type) {
              checkout_sort = true;
            } else {
              checkout_sort = false;
            }
          });
          // console.log("checkout", checkout, "checkout_sort", checkout_sort);
          if (checkout) {
            this.$message.error("请完善筛选条件信息");
            return;
          }
          if (checkout_sort) {
            this.$message.error("请完善排序条件信息");
            return;
          }
          this.sureSave();
        }
      });
    },
    sureSave() {
      const {
        rule_name,
        rule_desc,
        model_code,
        dimensionality,
        platform,
        media_id,
        media_type,
      } = this.form;
      this.btn_loading = true;
      let obj = {
        code: "EXTERNAL_DATA.account_group",
        condition_type: media_type,
        condition_value: media_id.key,
        condition_display: media_id.label.trim(),
      };

      let new_filtrate_list = JSON.parse(JSON.stringify(this.filtrate));
      new_filtrate_list = new_filtrate_list.concat(obj);
      new_filtrate_list.map((item) => {
        // console.log(123123);
        if (item.type == "between") {
          item.condition_value = this.onChangeDate(item.condition_value);
        }
        if (item.type == "date") {
          item.condition_value = this.onChangeOneDate(item.condition_value);
        }
        delete item.filter_type;
        delete item.filter_value;
        delete item.type;
      });
      let payload = {
        rule_name,
        rule_desc,
        model_info: {
          model_code,
          dimensionality,
          platform,
          sort_list: this.sort_filter,
          field_list: this.field_select,
          param_list: new_filtrate_list,
        },
      };
      if (this.detail_id!=0) {
        payload.id = this.detail_id;
      }
      _http
        .createRule(payload)
        .then((res) => {
          if (res.code == 200) {
            if (this.detail_id!=0) {
              this.exportRule(this.detail_id);
            } else {
              this.exportRule(res.data.id);
            }
          } else {
            this.$message.error(res.message);
            this.btn_loading = false;
          }
          // console.log("res", res);
        })
        .catch(() => {
          this.btn_loading = false;
        });
    },
    // 导出
    exportRule(value) {
      _http
        .exportRule(value)
        .then((res) => {
          // console.log("res", res);
          if (res.code == 200) {
            this.$notification.open({
              message: "数据正在导出",
              description: "正在为你跳转至“导出结果页”",
              icon: <a-icon type="smile" style="color: #108ee9" />,
            });
            setTimeout(() => {
              this.$router.push({
                name: "dataAnalyse:group",
              });
            }, 500);
          }
        })
        .finally(() => {
          this.btn_loading = false;
        });
    },
    cancelReturn() {
      let that = this;
      this.$confirm({
        title: "当前填写内容尚未保存，确定要离开吗？",
        okText: "确定",
        cancelText: "取消",
        onOk() {
          that.$router.go(-1);
        },
        onCancel() {
          // console.log("取消");
        },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.header_title {
  display: inline-block;
  padding-left: 12px;
  font-size: 12px;
}
.form_box {
  width: 1150px;
}
.col_border {
  border: 1px solid #ddd;
  text-align: center;
}
.border_r {
  border-right: none;
}
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>
