<template>
  <a-modal
    title="编辑"
    :visible="visible"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-tree
      v-model="checkedKeys"
      checkable
      :tree-data="tree_data"
      :expanded-keys="expandedKeys"
      :auto-expand-parent="autoExpandParent"
      @expand="onExpand"
      :selected-keys="selectedKeys"
      @select="onSelect"
    />
  </a-modal>
</template>
<script>
function transformList(arr, type = true) {
  if (!Array.isArray(arr)) {
    console.error(arr + "不是数组");
    return [];
  }
  if (arr.length === 0) {
    return [];
  }
  if (type) {
    const list = arr.map((item) => {
      let obj = {
        title: item.name,
        id: item.code,
        key: item.code,
      };
      if (Array.isArray(item.field_list) && item.field_list.length > 0) {
        let children = transformList(item.field_list);
        obj.children = children;
      }
      return obj;
    });
    return list;
  } else {
    let list = [];
    arr.map((item) => {
      list.push(item.code);
    });
    return list;
  }
}
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    field_list: {
      type: Array,
      default: [],
    },
    select_grounp: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      confirmLoading: false,
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      tree_data: [],
    };
  },
  watch: {
    field_list: {
      handler: function (val) {
        this.tree_data = transformList(val);
      },
    },
  },
  methods: {
    defaultValue(){
       this.checkedKeys = transformList(this.select_grounp, false);
    },
    handleOk(e) {
      this.confirmLoading = true;
      setTimeout(() => {
        this.confirmLoading = false;
        this.$emit("selectFiled", this.checkedKeys);
        this.$emit("colseModal");
      }, 300);
    },
    handleCancel() {
      this.$emit("colseModal");
    },
    onExpand(expandedKeys) {
      // if not set autoExpandParent to false, if children expanded, parent can not collapse.
      // or, you can remove all expanded children keys.
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    onSelect(selectedKeys, info) {
      this.selectedKeys = selectedKeys;
    },
  },
};
</script>
